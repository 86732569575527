<template>
  <v-row justify="center">
    <v-dialog v-model="mostrar" :max-width="ancho" persistent>
      <v-card>
        <v-card-title class="headline" v-html="cabecera"></v-card-title>

        <v-card-text v-html="cuerpo"></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="$emit('emitCancelar')">
            Cancelar
          </v-btn>

          <v-btn color="green darken-1" text @click="$emit('emitAceptar')">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "dialog-txt",
  props: ["cabecera", "cuerpo", "mostrar", "maxWidth"],
  computed: {
    ancho() {
      if (!this.maxWidth) {
        return 320;
      } else {
        return this.maxWidth;
      }
    }
  },
  mounted() {
    // console.log("desde el dialog");
  }
};
</script>

<style scoped></style>
